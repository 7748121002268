@import '../../../../node_modules/antd/lib/style/themes/default.less';
@primary-color: #3e4350;
@secondary-color: #407b82;
@text-color: #141414;
@headerandlinks-color: #2b4570;
@exit-color: #66008a;
@icons-color: #572943;
@input-color: #000000;
@hover-color: #25417e;
@page-color: #f7f7f7;

#root p {
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    color: @text-color;
}

#root h1 {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 64px !important;
    line-height: 60px !important;
    color: #00707d;
}

#root h2 {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 36px !important;
    line-height: 42px !important;
    color: #5a6487;
}

#root h3 {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 36px !important;
    line-height: 60px !important;
    color: #5a6487;
}

#root h4 {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: #5a6487;
}

#root .inner a {
    font-weight: bold;
    color: @headerandlinks-color;
    text-decoration: underline;
    text-decoration-color: @primary-color;
    text-decoration-thickness: 1;
    position: relative;
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#root .inner a:hover,
#root .inner a:active {
    text-decoration-thickness: 2;
}

#root b,
#root strong {
    font-weight: bold;
}

/* Responsive Adjustments */
@media only screen and (max-width: 1000px) {
    #root h1 {
        font-size: 54px !important;
        word-break: break-word;
    }
    #root h2 {
        line-height: 48px;
    }
    #root h3 {
        line-height: 48px;
    }
    #root h4 {
        line-height: 24px;
    }
}
#root .header {
    padding: 0px;
}

.nav-text {
    margin-left: 32px;
    flex: 1 1;
    margin-left: 24px;
}

.nav-text a {
    font-family: 'Ropa Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    color: #ffffff;
    line-height: 60px;
}

.header,
.header .top-menu,
.header .top-menu > li.ant-menu-item {
    background-color: @headerandlinks-color;
    background: @headerandlinks-color;
}

.bs-menu-item {
    color: #fff;
    margin-left: 32px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    transition: 0.2s ease border-bottom;
    padding: 0px;
    height: 30px;
    align-self: center;
    border-bottom: 3px solid transparent;
}

.bs-menu-item:hover,
.bs-menu-item.active {
    color: #fff;
    border-bottom: 3px solid #fff;
    transition: 0.2s ease border-bottom;
}

.header .top-menu > li.ant-menu-submenu {
    display: none !important;
}

.header .menu {
    display: flex;
}

.nav-icon {
    width: 118px !important;
    height: 58px !important;
}

.header .menu .nav-icon {
    flex: 1;
    margin-left: 24px;
}

.header .menu .addtl-menu {
    background-color: #c8c8c8;
    padding-right: 10px;
    margin-left: 24px;
    border-bottom: 2px solid @headerandlinks-color;
    margin-top: -2px;
}
.header .menu .addtl-menu > .bound-button.exit-button {
    background-color: @exit-color !important;
    border-color: @exit-color !important;
}

.ant-menu {
    font-size: 16px !important;
}

#root .ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
    color: #ffffff;
}

#subheader {
    display: flex;
    justify-content: flex-end;
}

#subheader .container {
    display: flex;
    padding: 6px 24px;
    border: 2px solid @primary-color;
    border-top: 0px solid;
    border-radius: 0px 0px 4px 4px;
    background-color: #ffffff;
}

#subheader .link-button {
    display: flex;
    align-items: center;
    cursor: pointer;
}

#subheader .link-button .icon {
    margin-right: 6px;
}

#subheader .link-button .text {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-right: 24px;
}

#subheader .link-button:hover .text {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: #f2bd31;
}

#subheader .link-button .secondary-bg {
    background-color: #f2bd31;
    border-color: #2b4570;
    color: #2b4570;
}

#subheader .link-button .secondary-bg:hover {
    background-color: #2b4570;
    border-color: #f2bd31;
    color: #f2bd31;
}

/* Responsive Adjustments */
.close-drawer {
    text-align: right;
    margin-top: -15px;
    margin-right: -15px;
    margin-bottom: 16px;
}
.r2a-mobile-divider {
    border-bottom: 1px solid #888888;
    margin: 8px 0px;
}
.r2a-menuitem-mobile {
    margin: 12px 0px;
    display: inline-block;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
}
.r2a-menuitem-mobile:hover,
.r2a-menuitem-mobile:active {
    border-bottom: 1px solid #f3be33;
    font-weight: bold;
}
#primary-fom {
    opacity: 0;
    transition-duration: 0.5s;
    transition-property: opacity;
}
#mobile-menu {
    display: none;
}
#desktop-menu {
    width: cacl(100% - 120px);
}
@media only screen and (max-width: 1000px) {
    #desktop-menu {
        display: none;
    }
    #mobile-menu {
        display: block;
    }
    #primary-fom {
        opacity: 1;
    }
    #subheader {
        display: none !important;
    }
}
@media only screen and (min-width: 1000px) {
    #desktop-menu {
        display: block;
    }
}
