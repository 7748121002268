@import '../../../../node_modules/antd/lib/style/themes/default.less';
@primary-color: #3e4350;
@secondary-color: #407b82;
@text-color: #141414;
@headerandlinks-color: #2b4570;
@exit-color: #66008a;
@icons-color: #572943;
@input-color: #000000;
@hover-color: #25417e;
@page-color: #f7f7f7;

#root p {
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    color: @text-color;
}

#root h1 {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 64px !important;
    line-height: 60px !important;
    color: #00707d;
}

#root h2 {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 36px !important;
    line-height: 42px !important;
    color: #5a6487;
}

#root h3 {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 36px !important;
    line-height: 60px !important;
    color: #5a6487;
}

#root h4 {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: #5a6487;
}

#root .inner a {
    font-weight: bold;
    color: @headerandlinks-color;
    text-decoration: underline;
    text-decoration-color: @primary-color;
    text-decoration-thickness: 1;
    position: relative;
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#root .inner a:hover,
#root .inner a:active {
    text-decoration-thickness: 2;
}

#root b,
#root strong {
    font-weight: bold;
}

/* Responsive Adjustments */
@media only screen and (max-width: 1000px) {
    #root h1 {
        font-size: 54px !important;
        word-break: break-word;
    }
    #root h2 {
        line-height: 48px;
    }
    #root h3 {
        line-height: 48px;
    }
    #root h4 {
        line-height: 24px;
    }
}
.questionnaire {
}

.questionnaire .question {
    border-left: 2px solid #dedede;
    padding-left: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
}

.questionnaire .question .content {
    display: flex;
}

#root .questionnaire .question .content .left,
#root .questionnaire .question .content .right,
#root .questionnaire .question .content .right p {
    color: #3e4350 !important;
}

#root .questionnaire .question.current .content .left,
#root .questionnaire .question.current .content .right,
#root .questionnaire .question.current .content .right > p {
    font-weight: normal !important;
    color: @hover-color !important;
}

.questionnaire .question .content .left {
    padding-right: 24px;
}

.questionnaire .answers {
    margin-left: 27px;
    margin-top: 24px;
}

.questionnaire .actions {
    margin-top: 48px;
    padding-top: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 48px;
    text-align: right;
}
.possible-answers {
    display: inline-block;
    margin-left: 200px;
}

/* Responsive Adjustments */
@media only screen and (max-width: 1000px) {
    .possible-answers {
        margin-top: 16px;
        margin-left: 0px;
    }
}
